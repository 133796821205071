<template>
	<div style="height: 100%">
		<elt-header title="字体图标" icon="icon-hash" >
			<div style="margin-top: 16px; color: #ffffff; font-size: 14px"><elt-icon :size="15" icon="icon-info"></elt-icon> 字体图标来自 <a href="https://www.iconfont.cn/collections/detail?cid=35922" style="text-decoration: none; color: #254CC2;" target="_blank">信域安全云网 - iconfont.cn</a> 使用方法详见</div>
		</elt-header>
		
		<div class="content">
			<div v-for="item in icons"  class="">
				<div style="color: #002AAB; font-size: 18px;">{{item.groupName}}</div>
				<div style="margin-top: 16px; margin-bottom: 32px; display: grid; grid-template-columns: repeat(14, calc( calc(100% - 156px) / 14 )); grid-gap: 12px;">
				<!-- :style="{'background-color': `${ ele.class ? '#fffec3' : '#fffec388'}`}" -->
					<div @click="copy" :data-clipboard-text="`icon-${ele.class}`" v-for="ele in item.group" :title="`icon-${ele.class}`" style="padding: 18px; color: #ffffff; border-radius: 8px; " class="flex column cross-center icon-card">
						<elt-icon :size="32" :icon="`icon-${ele.class}`"></elt-icon>
						<div style="font-size: 14px; margin-top: 18px;">{{ele.name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import Clipboard from 'clipboard'

export default {
	data() {
		return { icons: [] }
	},
	components: {  },
	created() { this.getIcons() },
	mounted() { },
	methods: {
		getIcons() {
			let url = process.env.BASE_URL + 'data/icon/' + 'icon.json'
			axios.get(url).then( res => {
				this.icons = res.data
			})
		},
		copy() {
			let clipboard = new Clipboard('.icon-card')
			clipboard.on('success', e => {
				this.$message({ message: `复制成功，复制内容为 ${e.text}`, type: 'success' });
				clipboard.destroy()
			})
			clipboard.on('error', e =>{
				console.log('该浏览器不支持复制')
				clipboard.destroy()
			})
		}
	}
}
</script>

<style>
/* .content::-webkit-scrollbar{ display: none; } */
</style>